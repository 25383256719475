import React, { useState, useEffect } from 'react';

// Images 
import UserAvatar from 'components/user/Avatar';
import MenuIcon from 'images/menu-icon.svg';
import { Menu, MenuItem } from '@szhsin/react-menu';
import PenIcon from "images/pen-icon.svg";
import DeleteIcon from "images/trash-bin.svg";


const CredentialsCard = ({ data, onEdit, onStatusChange, isCurrentUser }) => {

    const handleEdit = () => {
        if (typeof onEdit === "function") {
            onEdit(data);
        }
    }

    const handleStatusChange = () => {
        if (typeof onStatusChange === "function") {
            onStatusChange();
        }
    }

    return (
        <div className={`border border-primary-light flex flex-col gap-3 p-4 rounded-lg text-white`}>
            <div className={`flex items-center justify-between text-base`}>

                <div className={`flex gap-2 items-center`}>
                    <UserAvatar
                        bgClasses={`text-sm h-8 w-8`}
                        textClasses={`text-sm`}
                        username={data.firstName}
                    />
                    <span>{data?.firstName} {data?.lastName}</span>
                </div>

                <Menu
                    menuClassName={`box-border mr-2 min-w-[8rem] z-10 bg-primary p-2 rounded shadow-primary-1`}
                    menuButton={
                        <button className={`p-1 border border-primary-light rounded-lg`}>
                            <img className={`h-6 w-6`} src={MenuIcon} alt="Menu" />
                        </button>
                    }
                    align="end"
                >
                    <MenuItem onClick={handleEdit} className={`flex items-center gap-1 cursor-pointer m-0 p-[2px]`}>
                        <img className={`h-4 w-4`} src={PenIcon} alt="Edit" />
                        <span>Edit</span>
                    </MenuItem>

                    {
                        !isCurrentUser && (
                            <MenuItem onClick={handleStatusChange} className={`flex items-center gap-1 cursor-pointer m-0 p-[2px]`}>
                                <img className={`h-4 w-4`} src={DeleteIcon} alt="Delete" />
                                <span>
                                    {data?.isActive ? "Deactivate" : "Activate"}
                                </span>
                            </MenuItem>
                        )
                    }

                </Menu>
            </div>

            <div className={`flex flex-col items-start `}>
                <div>
                    <span className={`font-bold`}>Email ID: </span>
                    <span>{data?.email || "-NA-"}</span>
                </div>

                {/* <div>
                    <span className={`font-bold`}>Role: </span>
                    <span>{data?.employeeDetails?.roleId?.roleName || "-NA-"}</span>
                </div>

                <div>
                    <span className={`font-bold`}>Designation: </span>
                    <span>{data?.employeeDetails?.designationId?.designationName || "-NA-"}</span>
                </div> */}
            </div>
        </div>
    );
};

export default CredentialsCard;
