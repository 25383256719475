import { useEffect, useState } from "react";

import SearchIcon from "images/home/search.svg";
import MultiSelect from "components/Dropdown/MultiSelect";
import { getDataSourceList } from "api/admin/index";

const AllowedDataSources = ({ value, onChange }) => {

    const [tables, setTables] = useState([]);

    const fetchTables = async () => {
        try {
            const response = await getDataSourceList();
            const options = response.tables.map(x => ({ label: x, value: x }))
            setTables(options)
            console.log("Data -> ", options)

        } catch (error) {
            console.log("Error fetching tables", error)
        }
    }

    useEffect(() => {
        fetchTables();
    }, []);

    return (
        <div className="flex flex-col mt-10 gap-4">
            <div>
                <h2 className={`text-base`}>Allowed Data Sources</h2>
                <p className={`text-xs text-gray-300`}>Select tables you want to allow access for this user</p>
            </div>

            <div className="p-3 flex items-center justify-between border-b border-primary-light">
                <span className="text-sm">Search</span>
                <img className="w-4 h-4" src={SearchIcon} alt="Search" />
            </div>

            <div>
                <MultiSelect
                    placeholder={`select data-sources`}
                    options={tables}
                    selectedOption={value}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

export default AllowedDataSources