import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getLastQuestionList } from 'api/history/index';
import { Link, useSearchParams } from 'react-router-dom';
import ManageEmployeeDialog from "components/admin/ManageEmployeeDialog";
import { getAllCredentials, updateEmployee } from 'api/admin/index';
import CredentialsCard from "components/admin//CredentialsCard";
import { notifySuccess, notifyError } from 'utils/customToasts';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';
import { useAuth } from 'context/AuthContext';
import AllowedDataSources from 'components/admin/AllowedDataSources';

const InitialCredentials = {
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    contactNumber: "",
    countryCode: "",
    password: "",
    userRole: null,
    isActive: true
}


const ManageCredentialsComponent = () => {
    const [credentials, setCredentials] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showDeactivationDialog, setShowDeactivationDialog] = useState(false)
    const [data, setData] = useState({ records: [] });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    const closeDialog = () => {
        setShowDialog(false);
        setCredentials(null);
    }

    const openEditEmployee = (emp) => {
        setCredentials({
            ...emp,
            userRole: {
                label: emp.employeeDetails.roleId.roleName,
                value: emp.employeeDetails.roleId._id,
            }
        });
        setShowDialog(true);
    }

    const openActivationDialog = (emp) => {
        setCredentials(emp);
        setShowDeactivationDialog(true);
    }

    const closeActivationDialog = () => {
        setShowDeactivationDialog(false);
        setCredentials(null);
    }

    const fetchForumData = async () => {
        setLoading(true);
        try {
            const payload = {
                limit: 200,
                page: 1,
                // searchText: "",
                // tenantBranchId=66650c0ca929d44955d5f954&page=1
                // designationIds: [],
                // roleId: [],
            };
            const response = await getAllCredentials(payload);
            setData(response.data);
        } catch (err) {
            console.log("Error:", err);
        } finally {
            setLoading(false);
        }
    }

    const handleActivation = async () => {
        try {
            const payload = {
                isActive: !credentials.isActive,
                employeeId: credentials.employeeId
            }

            const response = await updateEmployee(payload);

            if (response?.statusCode !== "10000") {
                setLoading(false);
                notifyError(response?.message || "Status change failed");
                return;
            }
            if (typeof handleSave === "function") {
                handleSave(response.data)
            }
            setLoading(false);
            notifySuccess(response?.message || 'Profile status changed!');
            closeActivationDialog();
        } catch (error) {
            setLoading(false);
            notifyError('Something went wrong during submission');
        }
    }

    const handleSave = (newData) => {
        const dataIndex = data?.records?.findIndex(x => x._id === newData._id);

        if (dataIndex >= 0) {
            setData(prev => {
                const updatedData = { ...prev };
                updatedData.records[dataIndex] = { ...updatedData.records[dataIndex], ...newData };
                return updatedData;
            })
            return;
        }

        setData(prev => ({
            ...prev,
            records: [...prev.records, newData]
        }));
    }


    useEffect(() => {
        fetchForumData();
        return () => {
            setData({});
        }
    }, [])


    if (loading) {
        return <div className="text-white text-center mt-10">Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500 text-center mt-10">Error: {error?.message}</div>;
    }

    if (!data) {
        return <div className="text-white text-center mt-10">No data available</div>;
    }

    return (

        <div className="px-10 py-4">

            <div className="flex text-white justify-between items-center p-3 border-b border-primary-light">
                <span
                    className="font-medium text-xl"
                >
                    Manage credentials
                </span>

                <button onClick={() => { setShowDialog(true) }} className={`bg-primary-1 py-2 px-4 text-sm rounded-lg`}>
                    Create Credentials
                </button>
            </div>

            <div className="flex flex-col gap-6 mt-8">
                {
                    data?.records?.map((cred, index) => {
                        return (
                            <CredentialsCard
                                key={`credentials-${index}`}
                                data={cred}
                                onEdit={openEditEmployee}
                                onStatusChange={() => openActivationDialog(cred)}
                                isCurrentUser={user?.email === cred?.email && user?.username === cred?.username}
                            />
                        )
                    })
                }
            </div>

            <ManageEmployeeDialog
                isEdit={!!credentials}
                data={credentials || InitialCredentials}
                open={showDialog}
                onClose={closeDialog}
                onSave={handleSave}
            />

            <ConfirmDeleteModal
                className={`text-left`}
                open={showDeactivationDialog}
                title={credentials?.isActive ? "Deactivate User" : "Activate User"}
                content={`Are you sure you want to ${credentials?.isActive ? "deactivate" : "activate"} ${credentials?.firstName}?`}
                onConfirm={handleActivation}
                onCancel={closeActivationDialog}
            />
        </div>
    );
};

export default ManageCredentialsComponent;
