import { getFirstQuestionListThunk, getChatHistoryListByDateThunk } from "./thunk";
import { createSlice } from "@reduxjs/toolkit";

const initialMessage = {
  sender: 'bot',
  content: 'Hello, how can I help you?',
  sqlQuery: '',
  firstMessage: 'yes'
}

const initialState = {
  chats: [initialMessage],
  history: [],
  errors: {}
}

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChats: (state, action) => {
      const payload = action.payload;
      state.chats = payload;
    },

    setHistoryChats: (state, action) => {
      const { date, data = [] } = action.payload;
      state[date] = data;
    },

    updateChats: (state, action) => {
      const { index, data } = action.payload;
      state.chats[index] = { ...state.chats[index], ...data };
    },

    updateHistoryChats: (state, action) => {
      const { index, date, data } = action.payload;
      state[date][index] = { ...state[date][index], ...data };
    },

    addNewChat: (state, action) => {
      const payload = action.payload;
      state.chats.push(payload);
    },

    addNewHistoryChat: (state, action) => {
      const { date, data } = action.payload;
      state[date].push(data);
    },

    resetChats: (state, action) => {
      state.chats = [initialMessage];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFirstQuestionListThunk.fulfilled, (state, action) => {
      state.history = action.payload;
    });
    builder.addCase(getFirstQuestionListThunk.rejected, (state, action) => {
      state.errors.history = action.payload || null;
    });

    // builder.addCase(getChatHistoryListByDateThunk.fulfilled, (state, action) => {
    //   state[action.payload.date] = action.payload?.result?.reverse() || [];
    // });
    // builder.addCase(getChatHistoryListByDateThunk.rejected, (state, action) => {
    //   state.errors[action.date] = action.payload || null;
    // });
  },
});

export const {
  setChats,
  setHistoryChats,
  updateChats,
  updateHistoryChats,
  addNewChat,
  addNewHistoryChat,
  resetChats
} = chatSlice.actions;

export default chatSlice.reducer;