import ForgetPasswordPage from 'pages/auth/forgetPasswordPage';
import LoginPage from 'pages/loginPage';
import ResetPasswordPage from 'pages/auth/resetPasswordPage';
import OnboardingPage from "pages/auth/onboardingPage";

const publicRoutes = [
    {
        path: "/",
        name: "Login | Register",
        element: <LoginPage />,
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        element: <ForgetPasswordPage />,
    },
    {
        path: "/reset-password",
        name: "Reset Password",
        element: <ResetPasswordPage />,
    },
    {
        path: "/onboard",
        name: "Onboarding",
        element: <OnboardingPage />,
    }
]

export default publicRoutes;