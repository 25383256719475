import React, { useMemo } from 'react';

// Images
import { useAuth } from 'context/AuthContext';
import clsx from 'clsx';
import { getAvatarBGColor } from 'utils/helper';

const UserAvatar = ({
    title = "Profile",
    username = null,
    bgClasses = "",
    textClasses = "",
    profileImage = null,
}) => {

    const { user } = useAuth();
    const classes = "h-10 w-10 flex items-center justify-center rounded-full transition-transform duration-200 ease-in-out transform hover:scale-105"

    const bgColor = useMemo(() => {
        if (username) {
            return getAvatarBGColor(username);
        }
        const userId = user?.id || "User"
        return getAvatarBGColor(userId);
    }, [user?.id, username])

    const userName = username || user?.firstName || "U"
    const userImage = profileImage || user?.profilePicUrl;

    return (
        <button title={title} className={clsx(classes, bgColor, bgClasses)}>
            {
                (profileImage || user?.profilePicUrl) ? (
                    <img src={userImage} alt="Profile" className={clsx("cursor-pointer h-10 w-10 rounded-full", bgClasses)} />
                ) : (
                    <span className={clsx("text-black", textClasses)}>{userName?.[0]?.toUpperCase()}</span>
                )
            }
        </button>
    );
};

export default UserAvatar;