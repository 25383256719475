import { avatarBGColors } from 'constants';
export const generateDropdownOptions = (message, index) => {
	const restrictedCoulmn = [undefined, "month", "year", "date"]
	const forcastTypes = ["ARIMA", "DES", "MCMC", "Monte Carlo", "Scenario Analysis"]
	const columns = Object.keys(message?.graph?.[0] || {});
	const options = [];

	for (const column of columns) {

		if (restrictedCoulmn.includes(column?.trim()?.toLowerCase())) {
			continue;
		}

		for (const forecastType of forcastTypes) {
			options.push({
				label: `${forecastType} forecast for ${column}`,
				value: `${forecastType}-${column}`,
				column,
				forecastType: forecastType
			})
		}
	}

	return options;
}

/**
 * Excepts the json string and returns the javascript object Creted to handle errors & nested strings
 * @param {string} data JSON string
 * @returns {object}  Javascript Object | data(inputed)
 */
export const getJSONObject = (data) => {
	try {
		let parsedData = data;

		// Keep parsing while the result is a string
		while (typeof parsedData === "string") {
			parsedData = JSON.parse(parsedData);
		}

		return parsedData;
	} catch (err) {
		console.error("Error parsing JSON object:", err);
		return data;
	}
};

export const getTimeDistance = (date) => {

	const inputDate = new Date(date);

	// Ensure that the input is a valid Date object
	if (!(inputDate instanceof Date) || isNaN(inputDate.getTime())) {
		return "";
	}

	const now = new Date();
	const diffInMs = now - inputDate; // Difference in milliseconds
	const diffInSec = Math.floor(diffInMs / 1000); // Convert to seconds
	const diffInMin = Math.floor(diffInSec / 60); // Convert to minutes
	const diffInHour = Math.floor(diffInMin / 60); // Convert to hours
	const diffInDay = Math.floor(diffInHour / 24); // Convert to days
	const diffInMonth = Math.floor(diffInDay / 30); // Convert to months (approx)
	const diffInYear = Math.floor(diffInMonth / 12); // Convert to years (approx)

	// Return formatted string based on difference
	if (diffInSec < 60) {
		return `${diffInSec} second${diffInSec > 1 ? 's' : ''} ago`;
	} else if (diffInMin < 60) {
		return `${diffInMin} minute${diffInMin > 1 ? 's' : ''} ago`;
	} else if (diffInHour < 24) {
		return `${diffInHour} hour${diffInHour > 1 ? 's' : ''} ago`;
	} else if (diffInDay < 30) {
		return `${diffInDay} day${diffInDay > 1 ? 's' : ''} ago`;
	} else if (diffInMonth < 12) {
		return `${diffInMonth} month${diffInMonth > 1 ? 's' : ''} ago`;
	} else {
		return `${diffInYear} year${diffInYear > 1 ? 's' : ''} ago`;
	}
}

// Dynamic Avatar bg-color
export const getAvatarBGColor = (inputSeed) => {
	if (typeof inputSeed === 'number') {
		return avatarBGColors[inputSeed % avatarBGColors.length];
	}

	if (typeof inputSeed === 'string') {
		let hash = 0;
		for (let i = 0; i < inputSeed.length; i++) {
			hash = (hash << 5) - hash + inputSeed.charCodeAt(i);
		}
		return avatarBGColors[Math.abs(hash) % avatarBGColors.length];
	}

	return "bg-avatar"; // Default to gray
}

// get origin
export const getOrigin = () => {
	let origin = window.location.origin;

	// Custom handling for localhost (if needed)
	if (origin.includes('localhost')) {
		origin = 'http://localhost:3000'; 
	}

	return origin;
};

// Utility function to extract tenant name based on regex
const extractTenantNameFromRegex = (input, regex) => {
	const match = input.match(regex);
	return match ? match[1] : null; // Return matched value or null
};

// Extract tenant name from the subdomain of a URL
export const getTenantNameFromSubDomain = (input) => {
	const urlRegex = /^(?:https?:\/\/)?([\w-]+)\./; // Matches subdomain
	return extractTenantNameFromRegex(input, urlRegex)
};

// Extract tenant name from the domain in an email address
export const getTenantNameFromEmail = (input) => {
	const emailRegex = /@([\w-]+)\./; // Matches domain in email
	return extractTenantNameFromRegex(input, emailRegex);
}

// Helper function to extract tenantName from a single string
const getTenantName = (input) => {
	const urlRegex = /^(?:https?:\/\/)?([\w-]+)\./; // Matches subdomain
	const emailRegex = /@([\w-]+)\./; // Matches domain in email

	// Match for subdomain first
	const urlMatch = input.match(urlRegex);
	if (urlMatch) return urlMatch[1];

	// Match for domain in email
	const emailMatch = input.match(emailRegex);
	if (emailMatch) return emailMatch[1];

	return null;
};

// handle tenantName options, prioritize index wise.
// smaller index has higher priority.

export const extractTenantName = (...args) => {
	// Iterate through the arguments and return the first valid tenantName
	for (const arg of args) {
		const tenantName = getTenantName(arg);
		if (tenantName) return tenantName;
	}

	return null; // Return null if no valid tenantName is found
}

export const generateTenantName = (name) => {
	return name
		.toLowerCase()           // Convert the name to lowercase
		.replace(/\s+/g, '')     // Remove all spaces (including multiple spaces)
	//   .replace(/\d+/g, '');     // Remove all numbers
};